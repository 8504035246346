{
  "title": "Create account",
  "bcForWorkSubtitle": "Sign up to activate your {{businessName}} $t(common.bc) account",
  "name": "First name",
  "personalEmail": {
    "label": "Personal email",
    "description": "This will be the email you use to log into the app. This field is case sensitive."
  },
  "continueToPayment": "Continue to payment",
  "signUp": "Sign up",
  "acceptPrivacyPolicyAndTsAndCs": "I am over 18 and have read the <0>Privacy Policy</0> and <1>Terms & Conditions</1>",
  "acceptMarketing": "I am happy to receive marketing emails from $t(common.tbc) including free recipes, workouts and inspiration plus offers and promotions",
  "genericError": "Sorry, there was a problem submitting your details. Please try again later.",
  "form": {
    "error": {
      "required": {
        "name": "Name is required",
        "email": "Email address is required",
        "tsAndCs": "Please confirm that you accept the Privacy Policy and Terms and Conditions"
      },
      "too_big": {
        "name": "Name must be no longer than 13 characters"
      },
      "invalid": {
        "email": "Email address is an invalid format"
      },
      "email": {
        "disposable": "That email address doesn't look right. Please try another one.",
        "invalidDomain": "Please enter an email address with a valid domain name.",
        "typo": "Did you mean {{suggestedEmail}}?"
      },
      "challenge": {
        "invalid": "Please complete the challenge to prove you are human"
      }
    }
  }
}
