[
  {
    "title": "A structured plan tailored to you and your goals",
    "altText": "Structured plan",
    "images": [
      {
        "src": "img/features/structured-plan.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/features/structured-plan@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/features/structured-plan.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/features/structured-plan@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "title": "In-app planning tools to help you stay on track",
    "altText": "In-app planning",
    "images": [
      {
        "src": "img/features/in-app-planning.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/features/in-app-planning@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/features/in-app-planning.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/features/in-app-planning@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "title": "New recipes and workouts every month",
    "images": [
      {
        "src": "img/features/new-recipes.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/features/new-recipes@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/features/new-recipes.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/features/new-recipes@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "title": "Regular new workouts including Classes, Challenges and Series",
    "altText": "Workouts",
    "images": [
      {
        "src": "img/features/workouts.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/features/workouts@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/features/workouts.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/features/workouts@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  }
]
