{
  "paymentConfirmation": "Confirmation",
  "title": {
    "succeeded": "Bosh! Welcome to $t(common.tbc) app",
    "processing": "Payment processing. You'll receive an invoice once payment has been received.",
    "trialing": "Bosh! Welcome to $t(common.tbc) app",
    "active": "Bosh! Welcome to $t(common.tbc) app",
    "b2b": "Bosh! Your $t(common.bc) account is active"
  },
  "subtitle": {
    "firstPeriodDiscounted_true": {
      "freeTrial_true": {
        "upfrontPaymentRequired_true": "{{total}} will be billed after your {{count}} day free trial on <0>{{trialExpiry}}</0>, then {{subtotal}} {{interval}}",
        "upfrontPaymentRequired_false": "Your {{count}} day free trial has started and will end on <0>{{trialExpiry}}</0>. No payment required. You've got this!"
      },
      "freeTrial_false": {
        "upfrontPaymentRequired_true": "{{total}} will be billed for the first {{period}}, then {{subtotal}} {{interval}}",
        "upfrontPaymentRequired_false": null
      }
    },
    "firstPeriodDiscounted_false": {
      "freeTrial_true": {
        "upfrontPaymentRequired_true": "{{total}} will be billed after your {{count}} day free trial on <0>{{trialExpiry}}</0> and then {{interval}}",
        "upfrontPaymentRequired_false": "Your {{count}} day free trial has started and will end on <0>{{trialExpiry}}</0>. No payment required. You've got this!"
      },
      "freeTrial_false": {
        "upfrontPaymentRequired_true": "{{total}} will be billed {{interval}}",
        "upfrontPaymentRequired_false": null
      }
    }
  },
  "toGetStarted": "To get started, download the app and log in with your email. You won't need a password.",
  "downloadApp": "Download app",
  "description": [
    "We'll also send you a confirmation email. You might need to wait a few minutes and don't forget to check your spam folder.",
    "If it's not there, please <0>contact a support hero</0> who will be able to help you."
  ],
  "descriptionFreeTrialNoPayment": "We'll be in touch when your trial is coming to an end."
}
