{
  "bc": "Body Coach",
  "tbc": "The $t(common.bc)",
  "bcAccount": "$t(common.bc) account",
  "bcAccountWithName": "{{couponName}} $t(common.bc) account",
  "subscribeToTheBodyCoach": "Subscribe to $t(common.tbc)",
  "metaDescription": "Start your journey to a fitter, healthier, happier you with the new $t(common.bc) app. Welcome to the feel-good way to view transformation.",
  "iOSOrAndroidDeviceRequired": "iOS or Android device required",
  "error": "Error",
  "apply": "Apply",
  "remove": "Remove",
  "tsAndCs": {
    "url": "https://www.iubenda.com/terms-and-conditions/83525549",
    "text": "Terms & Conditions"
  },
  "privacyPolicy": {
    "url": "https://www.iubenda.com/privacy-policy/83525549",
    "text": "Privacy Policy"
  },
  "cookiePolicy": {
    "url": "https://www.iubenda.com/privacy-policy/83525549/cookie-policy",
    "text": "Cookie Policy"
  },
  "copyright": "© $t(common.tbc) {{year}}",
  "freeTrialInfo": "Your free trial ends on <0>{{endDate}}</0>. We'll notify you 2 days before. Cancel anytime.",
  "plan": {
    "bestValue": "Best value",
    "freeTrial_one": "{{count}} day free",
    "freeTrial_other": "{{count}} days free",
    "freeTrialWithTotal": "{{days}} day free trial then <0>{{total}}</0>",
    "freeTrialEndingMessage": "No surprises, we'll notify you 2 days before your free trial ends.",
    "label": "{{name}} ({{amount}})",
    "promoCode": "<0>{{code}}</0> coupon applied",
    "discount": "{{name}} discount",
    "paymentSummary": {
      "firstPeriodDiscounted_true": {
        "freeTrial_true": {
          "upfrontPaymentRequired_true_one": "{{count}} day free then {{total}} for the first {{period}}, then {{subtotal}} {{interval}}",
          "upfrontPaymentRequired_true_other": "{{count}} days free then {{total}} for the first {{period}}, then {{subtotal}} {{interval}}",
          "upfrontPaymentRequired_false_one": "{{count}} day free. No payment details required.",
          "upfrontPaymentRequired_false_other": "{{count}} days free. No payment details required."
        },
        "freeTrial_false": {
          "upfrontPaymentRequired_true": "{{total}} for the first {{period}}, then {{subtotal}} {{interval}}",
          "upfrontPaymentRequired_false": null
        }
      },
      "firstPeriodDiscounted_false": {
        "freeTrial_true": {
          "upfrontPaymentRequired_true_one": "{{count}} day free then {{total}} {{interval}}",
          "upfrontPaymentRequired_true_other": "{{count}} days free then {{total}} {{interval}}",
          "upfrontPaymentRequired_false_one": "{{count}} day free. No payment details required.",
          "upfrontPaymentRequired_false_other": "{{count}} days free. No payment details required."
        },
        "freeTrial_false": {
          "upfrontPaymentRequired_true": "{{total}} {{interval}}",
          "upfrontPaymentRequired_false": null
        }
      }
    },
    "paymentSummaryAutoRenews": "{{paymentSummary}}. Subscription auto-renews unless cancelled.",
    "pricePerMonth": "Equivalent to {{amount}} per month",
    "period": {
      "day": "day",
      "week": "week",
      "month": "month",
      "year": "year"
    },
    "interval": {
      "day": "daily",
      "week": "weekly",
      "month": "monthly",
      "year": "yearly"
    }
  }
}
